import {IWave} from "../../../types/wave";
import NpiInputForm from "../../input/form";
import {parseAnswersFormQuestion} from "../../../helpers/form";
import {IFormAnswer, IFormAnswerByQuestion, IFormImagesByQuestion, IQuestion} from "../../../types/question";
import {Space} from "antd";
import React, {Dispatch, useContext, useEffect, useMemo} from "react";
import {useNpiExternalPosContext} from "../../../contexts/external-pos-context";
import NpiLanguageContext from "../../../contexts/language-context";
import _ from "lodash";
import {INpiInputQuestionError} from "../../input/question";
import {IWavePosImage} from "../../../types/wave-pos-image";
import {convertWpiToUploadImage} from "../../input/image-uploader";
import {useControllableValue} from "ahooks";


interface INpiTabSurveyWaveContentProps {
    wave: IWave,
    errors?: INpiInputQuestionError[],
    loading?: boolean,
    formChanges?: IFormAnswerByQuestion,
    setFormChanges?: Dispatch<IFormAnswerByQuestion>,
    displayedQuestions?: IQuestion[],
    setDisplayedQuestions?: Dispatch<IQuestion[]>,
    imageQuestions?: IFormImagesByQuestion,
    setImageQuestions?: Dispatch<IFormImagesByQuestion>,
}

const NpiTabSurveyWaveContent = ({wave, errors, loading,...props}: INpiTabSurveyWaveContentProps) => {
    const {currentLang} = useContext(NpiLanguageContext);

    // get current POS context
    const {posId, pos, wavePosMappedByWaveId} = useNpiExternalPosContext();

    // get waves current values
    const tags = useMemo(() => wavePosMappedByWaveId[wave.id]?.tags, [wavePosMappedByWaveId, wave]);
    const waveAnswers = useMemo(() => wavePosMappedByWaveId[wave.id].answers ?? {}, [wavePosMappedByWaveId, wave]);
    const initialImageQuestions = useMemo(() => _.mapValues(wavePosMappedByWaveId[wave.id].imageQuestions, (list: IWavePosImage[], qId) => ({
        question_id: Number(qId),
        images: list.map(convertWpiToUploadImage)
    })), [wavePosMappedByWaveId, wave]);

    // tracked changes in form
    const [formChanges, setFormChanges] = useControllableValue<IFormAnswerByQuestion>(props, {
        valuePropName: 'formChanges',
        trigger: 'setFormChanges',
        defaultValue: {},
    });
    // track displayed questions
    const [, setDisplayedQuestions] = useControllableValue<IQuestion[]>(props, {
        valuePropName: 'displayedQuestions',
        trigger: 'setDisplayedQuestions',
        defaultValue: [],
    });
    // track image questions files
    const [imageQuestions, setImageQuestions] = useControllableValue<IFormImagesByQuestion>(props, {
        valuePropName: 'imageQuestions',
        trigger: 'setImageQuestions',
        defaultValue: {},
    });


    // -- convert images
    useEffect(() => {
        setImageQuestions(_.mapValues(wavePosMappedByWaveId[wave.id].imageQuestions, (list: IWavePosImage[], qId) => ({
            question_id: Number(qId),
            images: list.map(convertWpiToUploadImage)
        })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wavePosMappedByWaveId[wave.id]])

    // compute answers - do this ugly trick due to Answer PHP model not containing custom cast in order to handle both JSON and string cast
    const answers = useMemo(() => parseAnswersFormQuestion(
        Object.values({...waveAnswers, ...formChanges}),
        wave.questions
    ), [waveAnswers, formChanges, wave]);

    // re-map the change answers by Question ID (this is the format send to the server)
    const onFormChange = (value: IFormAnswer[]) => {
        const newChanges = value
            .map((answer: IFormAnswer) => ({...answer, ...{pos_id: posId, wave_id: wave.id}}))  // ensure a valid data
            .reduce((acc: IFormAnswerByQuestion, answer: IFormAnswer) => ({...acc, [answer['question_id']]: answer}), {});

        setFormChanges(newChanges)
    }

    // -- Track question displayed changes
    const onQuestionsDisplayChange = (newQuestions: IQuestion[]) => {
        setDisplayedQuestions(newQuestions.filter(q => !q.hiddenInForm));
    };

    return <Space direction="vertical" style={{width: '100%'}}>
        <NpiInputForm
            languageId={currentLang?.id ?? 0}
            item={wave}
            errors={errors}
            tags={tags}
            fields={pos}
            answers={answers ?? []}
            onChange={onFormChange}
            onQuestionsChange={onQuestionsDisplayChange}
            imageQuestions={imageQuestions ?? initialImageQuestions}
            onImageQuestionsChange={setImageQuestions}
            loading={loading}
        />
    </Space>
}

export default NpiTabSurveyWaveContent;